
body {
  font-family: 'Poppins';font-size: 22px;
}

/* Button */
 .btn {
  padding-left: 16px;
  padding-right: 16px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-weight: 500;
  cursor: pointer;
  display: block;
  text-align: center;
  border: 1px solid transparent;
}

.btn-expand {
  width: 100%;
}

.btn-main {
  background-color: #004680;
  color: #FFFFFF;
}

.btn-outline {
  background-color: #FFFFFF;
  color: #004680;
  border: 1px solid #004680;
}

.btn-warning {
  background-color: #CD7B2E;
}

.btn-success {
  background-color: #43936C;
}

.btn-info {
  background-color: #3267E3;
}

.btn-danger {
  background-color: #CB3A31;
  color: #FFFFFF;
}

/* Badge */
.badge {
  padding-left: 9px;
  padding-right: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.badge-main {
  background-color: #004680;
  color: #FFFFFF;
}

.badge-warning {
  background-color: #CD7B2E;
}

.badge-success {
  background-color: #43936C;
}

.badge-info {
  background-color: #3267E3;
}

.badge-danger {
  background-color: #CB3A31;
  color: #FFFFFF;
}

/* Form Input */
.input-group {
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
}

.input-group .label {
  font-weight: 500;
  margin-bottom: 5px;
}

.input-text {
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
  outline: none;
  font-size: 16px;
  background-color: #EBF0F4;
}

.input-group-image {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #EBF0F4;
  position: relative;
  height: 120px;
  border-radius: 9px;
}

.input-image-label {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.input-image {
  display: none;
}